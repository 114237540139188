import React, { useState, useEffect, useContext, Suspense } from 'react';
import { Modal, Input } from 'antd';

import { BrushContext } from '../contexts/BrushContext';

import Brush1 from '../brushes/Brush1';

const AceEditor = React.lazy(() => import('react-ace'));

const BrushEditorModal = ({ modalVisible, handleModalOk, handleModalCancel, existingBrush }) => {
  
  const { createBrush, updateBrush } = useContext(BrushContext);

  const defaultBrushName = 'Untitled Brush';

  const [brushName, setBrushName] = useState(defaultBrushName);
  const [showErrors, setShowErrors] = useState(false);
  const [editorCode, setEditorCode] = useState('');
  const [hasSyntaxError, setHasSyntaxError] = useState(false);

  useEffect(() => {
    
    // statically load or don't (also update in BrushContext)
    if(false) {
      // Fetch default brush code from the public directory
      fetch(process.env.PUBLIC_URL + '/brushes/brush1.js')
        .then((response) => response.text())
        .then((data) => {
          setEditorCode(data);
        });
    } else {
      setEditorCode(Brush1.toString());
    }
  }, []);

  useEffect(() => {
    if (existingBrush) {
      setBrushName(existingBrush.name);
      setEditorCode(existingBrush.brush.constructor.toString());
    } else {
      resetState();
    }
  }, [existingBrush]);

  const resetState = () => {
    setBrushName(defaultBrushName);
    setEditorCode(editorCode); // it will use the fetched default brush code
    setShowErrors(false);
  };

  const handleEditorChange = (newEditorCode) => {
    // update the editorCode state with the new editor code
    setEditorCode(newEditorCode);

    try {
      new Function(newEditorCode);
      setHasSyntaxError(false);
    } catch (err) {
      setHasSyntaxError(true);
    }
  };

  const handleOk = () => {
    setShowErrors(true);
    
    if (existingBrush) {
      updateBrush(existingBrush.key, brushName, editorCode);
    } else {
      createBrush(brushName, editorCode);
    }

    handleModalOk();
  };

  const handleCancel = () => {
    handleModalCancel();
  }

  useEffect(() => {
    // Load Ace Editor dependencies dynamically
    import('ace-builds/src-noconflict/mode-javascript');
    import('ace-builds/src-noconflict/theme-monokai');
    import('ace-builds/src-noconflict/ext-language_tools');
  }, []);

  return (
    <Modal
      title={existingBrush ? 'Edit Brush' : 'Create Brush'}
      visible={modalVisible}
      onOk={handleOk}
      okText={"Save"}
      onCancel={handleCancel}
      width={800}
    >
      <Input
        placeholder="Untitled Brush"
        value={brushName}
        onChange={(e) => setBrushName(e.target.value)}
        style={{ marginBottom: 4 }}
      />
      {showErrors && brushName.length === 0 && <div style={{ color: 'red', marginBottom: 8 }}>Name is required.</div>}
      <Suspense fallback={<div>Loading Editor...</div>}>
        <AceEditor
          mode="javascript"
          theme="monokai"
          value={editorCode}
          name="p5jsEditor"
          editorProps={{ $blockScrolling: true }}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          setOptions={{
            enableBasicAutocompletion: true,
           enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
            useWorker: false,
          }}
          onChange={handleEditorChange}
          style={{ width: '100%', minHeight: '400px', borderRadius: '4px' }}
        />
      </Suspense>
      {showErrors && hasSyntaxError && <div style={{ color: 'red', marginTop: 8 }}>The code has syntax errors.</div>}
    </Modal>
  );
};

export default BrushEditorModal;