import React, { createContext, useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { CanvasContext } from './CanvasContext';

import Brush1 from '../brushes/Brush1';
import Brush2 from '../brushes/Brush2';
import Brush3 from '../brushes/Brush3';
import Brush4 from '../brushes/Brush4';
import Brush5 from '../brushes/Brush5';
import Brush6 from '../brushes/Brush6';
import Brush7 from '../brushes/Brush7';

import p5 from "p5";

import { 
  FormatPainterOutlined,
} from '@ant-design/icons';

export const BrushContext = createContext();

const BrushContextProvider = ({ children }) => {

  const { saveCanvasState } = useContext(CanvasContext);

  const [brushes, setBrushes] = useState([]);
  const [currentBrush, setCurrentBrush] = useState(null);

  // Initialize brushes using fetch
  useEffect(() => {

    if (false) {
      const brushFiles = [
        '/brushes/brush1.js',
        '/brushes/brush2.js',
        '/brushes/brush3.js',
        '/brushes/brush4.js',
        '/brushes/brush5.js',
        '/brushes/brush6.js',
        '/brushes/brush7.js'
      ];

      const brushNames = [
        'Ordered Chaos',
        'Repeating Rings',
        'Spin Me Round',
        'Random Pro',
        'Irregular Circle',
        'Color Wave',
        'Fireworks'
      ];

      Promise.all(brushFiles.map(file => fetch(file).then(res => res.text())))
      .then(sources => {
          const newBrushes = sources.map((source, index) => {
              let BrushClass;
              try {
                  // Wrap the source in a function that returns the class
                  const wrappedSource = new Function(`return ${source}`);
                  BrushClass = wrappedSource();
              } catch (e) {
                  console.error('Error evaluating brush code:', e);
                  return null;
              }
              return {
                  name: brushNames[index],
                  key: uuidv4(),
                  icon: <FormatPainterOutlined />,
                  brush: new BrushClass(p5),
                  draw: function(p5) {
                      this.brush.setP5Instance(p5);
                      this.brush.touched(p5.mouseX, p5.mouseY);
                      saveCanvasState(p5);
                  }
              };
          }).filter(Boolean); // Filter out any null entries

          setBrushes(newBrushes);
          setCurrentBrush(newBrushes[0]);
      });
    } else {
      const brushClasses = [
        Brush1,
        Brush2,
        Brush3,
        Brush4,
        Brush5,
        Brush6,
        Brush7
      ];

      const brushNames = [
        'Ordered Chaos',
        'Repeating Rings',
        'Spin Me Round',
        'Random Pro',
        'Irregular Circle',
        'Color Wave',
        'Fireworks'
      ];

      const newBrushes = brushClasses.map((BrushClass, index) => {
          return {
              name: brushNames[index],
              key: uuidv4(),
              icon: <FormatPainterOutlined />,
              brush: new BrushClass(p5),
              draw: function(p5) {
                  this.brush.setP5Instance(p5);
                  this.brush.touched(p5.mouseX, p5.mouseY);
                  saveCanvasState(p5);
              }
          };
      }).filter(Boolean);

      setBrushes(newBrushes);
      setCurrentBrush(newBrushes[0]);
    }

  }, []);

  const selectBrush = (key) => {
    const selectedBrush = brushes.find(brush => brush.key === key);
    if (selectedBrush) {
      setCurrentBrush(selectedBrush);
    }
  };

  const addBrush = (newBrush) => {
    setBrushes(prevBrushes => [...prevBrushes, newBrush]);
  }

  const createBrush = (name, code) => {

    const newBrushKey = uuidv4();

    let NewBrushClass;
    try {
      eval(`NewBrushClass = ${code}`);
    } catch (e) {
      console.error('Error evaluating brush code:', e);
      return;
    }

    const newBrush = {
      name: name,
      key: newBrushKey,
      icon: <FormatPainterOutlined />,
      brush: new NewBrushClass(p5),
      draw: function(p5) {
        this.brush.setP5Instance(p5);
        this.brush.touched(p5.mouseX, p5.mouseY);
      }
    };

    addBrush(newBrush);
  };

  const updateBrush = (key, brushName, brushCode) => {
    // Find the brush to be updated
    const brushIndex = brushes.findIndex(brush => brush.key === key);
    if (brushIndex === -1) {
      console.error('Brush not found');
      return;
    }

    // Evaluate the new brush code
    let UpdatedBrushClass;
    try {
      eval(`UpdatedBrushClass = ${brushCode}`);
    } catch (e) {
      console.error('Error evaluating brush code:', e);
      return;
    }

    // Create the updated brush object
    const updatedBrush = {
      name: brushName,
      key,
      icon: <FormatPainterOutlined />,
      brush: new UpdatedBrushClass(p5),
      draw: function(p5) {
        this.brush.setP5Instance(p5);
        this.brush.touched(p5.mouseX, p5.mouseY);
      }
    };

    // Update the brush in the array
    setBrushes(prevBrushes => {
      const updatedBrushes = [...prevBrushes];
      updatedBrushes[brushIndex] = updatedBrush;
      return updatedBrushes;
    });
  };

  return (
    <BrushContext.Provider value={{ currentBrush, brushes, selectBrush, createBrush, updateBrush }}>
      {children}
    </BrushContext.Provider>
  );
};

export default BrushContextProvider;
