import React, { useRef, useEffect, useMemo, useContext } from 'react';
import { ReactP5Wrapper } from "@p5-wrapper/react";
import { CanvasContext } from '../contexts/CanvasContext';

function CanvasContainer({ currentBrush, p5Ref, disabled }) {
	const containerRef = useRef();
	const brushRef = useRef();
	const { isPerformingAction, saveCanvasState, markDrawing, recordDrawing } = useContext(CanvasContext);
	let mousePressedInsideCanvas = false;

	useEffect(() => {
		brushRef.current = currentBrush;
	}, [currentBrush]);

	const sketch = useMemo(() => {
		return (p5) => {
			p5Ref.current = p5;

			const drawWithBrush = (p5) => {
				if (isPerformingAction.current) return false;
				
				const brush = brushRef.current;
				if (brush && brush.draw) {
					const x = p5.touches?.[0]?.x ?? p5.mouseX;
					const y = p5.touches?.[0]?.y ?? p5.mouseY;
					
					if (!p5.mouseIsPressed || p5.canvas !== document.elementFromPoint(x, y)) {
						return false;
					}
					
					if (mousePressedInsideCanvas || (x > 0 && x < p5.width && y > 0 && y < p5.height)) {
						mousePressedInsideCanvas = true;
						brush.draw(p5);
						recordDrawing();
					}
				}
				return false;
			}

			p5.setup = () => {
				const container = containerRef.current;
				const canvas = p5.createCanvas(container.clientWidth, container.clientHeight);
				p5.background(0);
			};

			p5.touchStarted = (event) => {
				if (disabled || isPerformingAction.current) {
					event.preventDefault();
					event.stopPropagation();
					return false;
				}
				markDrawing(p5, true);
				return drawWithBrush(p5);
			};

			p5.touchEnded = (event) => {
				mousePressedInsideCanvas = false;
				markDrawing(p5, false);
			};

			p5.mouseDragged = () => {
				if (disabled || isPerformingAction.current) return false;
				drawWithBrush(p5);
				return false;
			};

			p5.mousePressed = () => {
				if (disabled || isPerformingAction.current) return false;
				markDrawing(p5, true);
				drawWithBrush(p5);
				return false;
			};

			p5.mouseReleased = () => {
				mousePressedInsideCanvas = false;
				markDrawing(p5, false);
			};

			p5.windowResized = () => {
				const container = containerRef.current;
				p5.resizeCanvas(container.clientWidth, container.clientHeight);
			};
		};
	}, [disabled, isPerformingAction, saveCanvasState, markDrawing, recordDrawing]);

	return (
		<div 
			ref={containerRef} 
			style={{ 
				width: '100%', 
				height: '100%',
				overflow: 'hidden',
				touchAction: 'none',
				pointerEvents: disabled ? 'none' : 'auto'
			}}
		>
			<ReactP5Wrapper sketch={sketch} />      
		</div>
	);
}

export default CanvasContainer;
