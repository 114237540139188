export default class Brush7 {
  
  constructor(p5) {
    this.p5 = p5;

    const properties = {
      // Fireworks Properties
      particleCount: 150,
      explosionRadius: 150,
      minSpeed: 3,
      maxSpeed: 8,
      gravity: 0.1,
      fadeRate: 5,
      colors: ['#FF5733', '#33FF57', '#3357FF', '#F1C40F', '#8E44AD', '#FFC300', '#DAF7A6'],
      particleSize: 6,
      explosionDuration: 2000,
    };

    Object.assign(this, properties);
  }

  setP5Instance(p5) {
    this.p5 = p5;
  }

  touched(x, y) {
    this.explode(x, y);
  }

  explode(x, y) {
    const p5 = this.p5;

    for (let i = 0; i < this.particleCount; i++) {
      const angle = p5.random(0, p5.TWO_PI);
      const speed = p5.random(this.minSpeed, this.maxSpeed);
      const color = this.colors[p5.floor(p5.random(this.colors.length))];

      // Create a particle
      this.createParticle(x, y, angle, speed, color);
    }
  }

  createParticle(x, y, angle, speed, color) {
    const p5 = this.p5;

    // Particle properties
    const particle = {
      x: x,
      y: y,
      vx: speed * p5.cos(angle),
      vy: speed * p5.sin(angle),
      life: 255,
      color: color,
      size: this.particleSize,
      gravity: this.gravity,
      explosionStart: Date.now(),
    };

    // Update particle position
    const update = () => {
      const elapsed = Date.now() - particle.explosionStart;

      // Apply gravity
      particle.vy += particle.gravity;

      // Update position
      particle.x += particle.vx;
      particle.y += particle.vy;

      // Decrease life for fading effect
      particle.life -= this.fadeRate;

      // Draw the particle
      p5.fill(p5.color(particle.color).levels[0], p5.color(particle.color).levels[1], p5.color(particle.color).levels[2], particle.life);
      p5.noStroke();
      p5.ellipse(particle.x, particle.y, particle.size, particle.size);

      // Remove particle if life is over
      if (particle.life > 0 && elapsed < this.explosionDuration) {
        requestAnimationFrame(update);
      }
    };

    update();
  }
} 