import './App.css';
import React, { useState, useRef, useEffect, useContext } from 'react';

import CanvasContainer from './components/CanvasContainer.js';
import BrushPropertiesSidebar from './components/BrushPropertiesSidebar.js';
import BrushEditorModal from './components/BrushEditorModal.js';

import { BrushContext } from './contexts/BrushContext';
import { CanvasContext } from './contexts/CanvasContext';

import { ConfigProvider, theme } from "antd";
import { Layout, Menu, Button, Tooltip, Select, Row, Col } from 'antd';
import { 
  PlusCircleOutlined,
  DownloadOutlined, 
  UndoOutlined, 
  RedoOutlined, 
  DeleteOutlined, 
  CodeOutlined,
  SettingOutlined
} from '@ant-design/icons';

const { darkAlgorithm } = theme;
const { Header, Content, Sider } = Layout;

function App() {
  const { currentBrush, brushes, selectBrush } = useContext(BrushContext);
  const { clearCanvas, saveCanvasToFile, undoCanvas, redoCanvas } = useContext(CanvasContext);

  const [selectedKey, setSelectedKey] = useState(null);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedEditBrush, setSelectedEditBrush] = useState(false);

  const p5Ref = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showProperties, setShowProperties] = useState(false);

  useEffect(() => {
    selectBrush(selectedKey);
  }, [selectedKey]);

  useEffect(() => {
    if(currentBrush) { //todo: not sure how this doesn't cause a endless loop
      setSelectedKey(currentBrush.key);
    }
  }, [currentBrush]);

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNewBrushClick = () => {
    setSelectedEditBrush(false);
    setIsEditModalVisible(true);
  };

  const handleEditBrushClick = () => {
    setSelectedEditBrush(true);
    setIsEditModalVisible(true);
  };

  const handleModalOk = () => {
    setIsEditModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleSaveCanvas = () => {
     saveCanvasToFile(p5Ref.current);
  };

  const handleClearCanvas = () => {
    clearCanvas(p5Ref.current);
  };

  const handleUndoCanvas = () => {
    undoCanvas(p5Ref.current);
  };

  const handleRedoCanvas = () => {
    redoCanvas(p5Ref.current);
  };

  // Mobile layout
  if (isMobile) {
    return (
      <ConfigProvider theme={{ algorithm: darkAlgorithm }}>
        <Layout style={{ minHeight: '100vh', background: 'none', overflow: 'hidden' }}>
          {/* Canvas as background */}
          <div style={{ 
            position: 'fixed', 
            top: 0, 
            left: 0, 
            width: '100vw', 
            height: '100vh', 
            zIndex: 0,
            overflow: 'hidden',
          }}>
            <CanvasContainer 
              currentBrush={currentBrush} 
              p5Ref={p5Ref}
              disabled={isEditModalVisible}
              style={{ 
                width: '100%', 
                height: '100%',
              }} 
            />
          </div>

          {/* Controls overlay */}
          <div 
            style={{ 
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2,
              display: 'flex', 
              flexDirection: 'column',
              pointerEvents: 'none'
            }}
          >
            {/* Navbar */}
            <Header style={{ 
              background: 'rgba(0, 0, 0, 0.5)', 
              backdropFilter: 'blur(10px)',
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              padding: '0 16px',
              pointerEvents: 'auto',
              touchAction: 'manipulation',
              zIndex: 2
            }}>
              <span className="rainbow-text" style={{ color: '#434343', fontWeight: '900' }}>GENATO</span>
              <div style={{ 
                display: 'flex', 
                gap: '8px',
                touchAction: 'manipulation'
              }}>
                <Tooltip title="Save">
                  <Button 
                    type="text" 
                    icon={<DownloadOutlined />} 
                    onClick={handleSaveCanvas}
                    onTouchEnd={(e) => {
                      e.stopPropagation();
                      handleSaveCanvas();
                    }}
                    style={{ minHeight: '44px', minWidth: '44px' }}
                  />
                </Tooltip>
                <Tooltip title="Clear">
                  <Button 
                    type="text" 
                    icon={<DeleteOutlined />} 
                    onClick={handleClearCanvas}
                    onTouchEnd={(e) => {
                      e.stopPropagation();
                      handleClearCanvas();
                    }}
                    style={{ minHeight: '44px', minWidth: '44px' }}
                  />
                </Tooltip>
                <Tooltip title="Undo">
                  <Button 
                    type="text" 
                    icon={<UndoOutlined />} 
                    onClick={handleUndoCanvas}
                    onTouchEnd={(e) => {
                      e.stopPropagation();
                      handleUndoCanvas();
                    }}
                    style={{ minHeight: '44px', minWidth: '44px' }}
                  />
                </Tooltip>
                <Tooltip title="Redo">
                  <Button 
                    type="text" 
                    icon={<RedoOutlined />} 
                    onClick={handleRedoCanvas}
                    onTouchEnd={(e) => {
                      e.stopPropagation();
                      handleRedoCanvas();
                    }}
                    style={{ minHeight: '44px', minWidth: '44px' }}
                  />
                </Tooltip>
              </div>
            </Header>

            {/* Bottom Controls */}
            <div style={{ 
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              padding: '16px',
              background: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(10px)',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              pointerEvents: 'auto',
              touchAction: 'manipulation',
              maxHeight: '80vh',
              zIndex: 3,
              isolation: 'isolate'
            }}
            >
              {/* Brush selector and buttons - these stay fixed */}
              <div style={{ 
                flexShrink: 0,
                pointerEvents: 'auto',
                touchAction: 'manipulation'
              }}>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Select
                    style={{ flex: 1 }}
                    size="large"
                    value={selectedKey}
                    onChange={(value) => {
                      if (value === '0') {
                        handleNewBrushClick();
                      } else {
                        setSelectedKey(value);
                      }
                    }}
                    dropdownStyle={{ zIndex: 1000 }}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    onClick={(e) => e.stopPropagation()}
                    onTouchStart={(e) => e.stopPropagation()}
                  >
                    {brushes.map((menuItem) => (
                      <Select.Option key={menuItem.key} value={menuItem.key}>
                        {menuItem.name}
                      </Select.Option>
                    ))}
                    <Select.Option key="0" value="0">
                      <span style={{}}> <PlusCircleOutlined /> New Brush </span>
                    </Select.Option>
                  </Select>
                  <Tooltip title="Edit Brush">
                    <Button type="default" size="large" icon={<CodeOutlined />} onClick={handleEditBrushClick} />
                  </Tooltip>
                  <Tooltip title="Brush Properties">
                    <Button 
                      type="default" 
                      size="large" 
                      icon={<SettingOutlined />} 
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowProperties(!showProperties);
                      }}
                      onTouchStart={(e) => e.stopPropagation()}
                    />
                  </Tooltip>
                </div>
              </div>

              {/* Scrollable properties panel */}
              {showProperties && (
                <div style={{ 
                  background: 'rgba(0, 0, 0, 0.5)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: '8px',
                  padding: '16px',
                  overflowY: 'auto',
                  flexGrow: 1,      // Allow this to grow
                  flexShrink: 1,    // Allow this to shrink
                  minHeight: 0,     // Important for proper flexbox scrolling
                  WebkitOverflowScrolling: 'touch',
                  pointerEvents: 'auto',
                  touchAction: 'pan-y'
                }}>
                  <BrushPropertiesSidebar brush={currentBrush} />
                </div>
              )}
            </div>
          </div>

          <BrushEditorModal
            modalVisible={isEditModalVisible}
            handleModalOk={handleModalOk}
            handleModalCancel={handleModalCancel}
            existingBrush={selectedEditBrush ? currentBrush : null}
          />
        </Layout>
      </ConfigProvider>
    );
  }

  return (
    <ConfigProvider theme={{ algorithm: darkAlgorithm }}>
      <Layout style={{ minHeight: '100vh', background: 'none', overflow: 'hidden' }}>
        {/* Canvas as background */}
        <div style={{ 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          width: '100vw', 
          height: '100vh', 
          zIndex: 0,
          overflow: 'hidden',
        }}>
          <CanvasContainer 
            currentBrush={currentBrush} 
            p5Ref={p5Ref}
            disabled={isEditModalVisible}
            style={{ 
              width: '100%', 
              height: '100%',
            }} 
          />
        </div>

        {/* Controls overlay */}
        <div 
          style={{ 
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            display: 'flex', 
            flexDirection: 'column',
            pointerEvents: 'none'
          }}
        >
          {/* Top bar with logo */}
          <div style={{ 
            width: '100%', 
            display: 'flex', 
            justifyContent: 'space-between', 
            pointerEvents: 'auto',
            padding: '16px',
          }}>
            <span className="rainbow-text" style={{ color: '#434343', fontWeight: '900' }}>GENATO</span>
            <div></div>
          </div>

          {/* Controls container aligned to left */}
          <div style={{ 
            alignSelf: 'flex-start',
            display: 'flex', 
            gap: '16px', 
            alignItems: 'flex-start',
            pointerEvents: 'auto',
            touchAction: 'none',
            marginLeft: '16px'
          }}>
            {/* Brush controls and properties panel */}
            <div style={{ 
              width: '270px',
              background: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(10px)',
              padding: '16px',
              borderRadius: '8px',
              display: 'flex', 
              flexDirection: 'column',
              gap: '16px',
              maxHeight: 'calc(100vh - 100px)',
              overflowY: 'auto',
              WebkitOverflowScrolling: 'touch'
            }}>
              <div style={{ display: 'flex', gap: '8px' }}>
                <Select
                  style={{ flex: 1 }}
                  size="large"
                  value={selectedKey}
                  onChange={(value) => {
                    if (value === '0') {
                      handleNewBrushClick();
                    } else {
                      setSelectedKey(value);
                    }
                  }}
                >
                  {brushes.map((menuItem) => (
                    <Select.Option key={menuItem.key} value={menuItem.key}>
                      {menuItem.name}
                    </Select.Option>
                  ))}
                  <Select.Option key="0" value="0">
                    <span> <PlusCircleOutlined /> New Brush </span>
                  </Select.Option>
                </Select>
                <Tooltip title="Edit Brush">
                  <Button type="default" size="large" icon={<CodeOutlined />} onClick={handleEditBrushClick} />
                </Tooltip>
              </div>
              <BrushPropertiesSidebar brush={currentBrush} />
            </div>

            {/* Canvas controls */}
            <div style={{ 
              background: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(10px)',
              padding: '8px',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              pointerEvents: 'auto'
            }}>
              <Tooltip title="Save" placement="right">
                <Button type="text" icon={<DownloadOutlined />} onClick={handleSaveCanvas} />
              </Tooltip>
              <Tooltip title="Clear" placement="right">
                <Button type="text" icon={<DeleteOutlined />} onClick={handleClearCanvas} />
              </Tooltip>
              <Tooltip title="Undo" placement="right">
                <Button type="text" icon={<UndoOutlined />} onClick={handleUndoCanvas} />
              </Tooltip>
              <Tooltip title="Redo" placement="right">
                <Button type="text" icon={<RedoOutlined />} onClick={handleRedoCanvas} />
              </Tooltip>
            </div>
          </div>
        </div>

        <BrushEditorModal
          modalVisible={isEditModalVisible}
          handleModalOk={handleModalOk}
          handleModalCancel={handleModalCancel}
          existingBrush={selectedEditBrush ? currentBrush : null}
        />
      </Layout>
    </ConfigProvider>
  );
}

export default App;
