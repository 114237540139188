import React, { createContext, useCallback, useRef, useEffect } from 'react';

export const CanvasContext = createContext();

const MAX_HISTORY_SIZE = 100;

const CanvasContextProvider = ({ children }) => {
  const canvasHistory = useRef([]);
  const historyIndex = useRef(-1);
  const isPerformingAction = useRef(false);
  const isDrawing = useRef(false);
  const hasDrawnSinceLastSave = useRef(false);

  const saveCanvasState = useCallback((p5) => {
    if (isPerformingAction.current || !hasDrawnSinceLastSave.current) return;
    
    const canvas = p5.canvas;
    const ctx = canvas.getContext('2d');
    const currentState = ctx.getImageData(0, 0, canvas.width, canvas.height);
    
    // Remove any states after current index when new drawing occurs
    let newCanvasHistory = canvasHistory.current.slice(0, historyIndex.current + 1);
    
    // Remove oldest states if exceeding max size
    if (newCanvasHistory.length >= MAX_HISTORY_SIZE) {
      newCanvasHistory = newCanvasHistory.slice(-MAX_HISTORY_SIZE + 1);
      historyIndex.current = Math.min(historyIndex.current, MAX_HISTORY_SIZE - 2);
    }
    
    newCanvasHistory.push(currentState);
    canvasHistory.current = newCanvasHistory;
    historyIndex.current = newCanvasHistory.length - 1;
    hasDrawnSinceLastSave.current = false;
  }, []);

  const undoCanvas = useCallback((p5) => {
    if (historyIndex.current > 0 && canvasHistory.current.length > 0) {
      isPerformingAction.current = true;
      historyIndex.current -= 1;
      
      const prevState = canvasHistory.current[historyIndex.current];
      const ctx = p5.canvas.getContext('2d');
      
      p5.clear();
      ctx.putImageData(prevState, 0, 0);
      
      isPerformingAction.current = false;
    }
  }, []);

  const redoCanvas = useCallback((p5) => {
    if (historyIndex.current < canvasHistory.current.length - 1) {
      isPerformingAction.current = true;
      historyIndex.current += 1;
      
      const nextState = canvasHistory.current[historyIndex.current];
      const ctx = p5.canvas.getContext('2d');
      
      p5.clear();
      ctx.putImageData(nextState, 0, 0);
      
      isPerformingAction.current = false;
    }
  }, []);

  const clearCanvas = useCallback((p5) => {
    if (p5?.canvas) {
      isPerformingAction.current = true;
      p5.clear();

      const backgroundColor = Math.random() > 0.5 ? 255 : 0;
      p5.background(backgroundColor);

      // Save the cleared state to history
      const ctx = p5.canvas.getContext('2d');
      const currentState = ctx.getImageData(0, 0, p5.canvas.width, p5.canvas.height);
      canvasHistory.current = [currentState];
      historyIndex.current = 0;

      isPerformingAction.current = false;
    }
  }, []);

  const saveCanvasToFile = useCallback((p5) => {
    if (p5?.canvas) {
      const date = new Date();
      const timestamp = date.toISOString().replace(/[:.]/g, "-");
      const filename = `canvas-${timestamp}.png`;
      p5.save(filename);
    }
  }, []);

  const markDrawing = useCallback((p5, isDraw) => {
    isDrawing.current = isDraw;
    if (!isDraw && hasDrawnSinceLastSave.current) {
      saveCanvasState(p5);  // Save state when drawing ends
    }
  }, [saveCanvasState]);

  const recordDrawing = useCallback(() => {
    hasDrawnSinceLastSave.current = true;
  }, []);

  return (
    <CanvasContext.Provider value={{
      clearCanvas, 
      saveCanvasState, 
      undoCanvas, 
      redoCanvas, 
      saveCanvasToFile,
      isPerformingAction,
      markDrawing,
      recordDrawing
    }}>
      {children}
    </CanvasContext.Provider>
  );
};

export default CanvasContextProvider;
